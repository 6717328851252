/** @jsx jsx */
import { jsx, Spinner } from "theme-ui";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

export default function LoadingStatusPage() {
  return (
    <Layout>
      <Spinner sx={{ m: "auto" }} />
    </Layout>
  );
}

export const Head = () => (
  <Seo>
    <meta name="robots" content="noindex, nofollow" />
  </Seo>
);
